import api from "./api.js";

const fetchThemeSetting = async ({username}) => {
  const response = await api.actionHandler({
    method: "get",
    url: api.themeSettingURL.replace("{username}", username),
  });

  return response.data;
};

export default fetchThemeSetting;
