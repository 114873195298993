import api from "./api.js";

const fetchSubMenuList = async ({ selectedID, username }) => {
  const response = await api.actionHandler({
    method: "get",
    url: api.subMenuListURL
      .replace("{id}", selectedID)
      .replace("{username}", username),
  });

  return response.data;
};

export default fetchSubMenuList;
