import { Typography } from "@mui/material";
import React from "react";

const ItemDescription = ({ description, trim,themeData }) => {
  return (
    <Typography sx={{ fontSize: 12, wordBreak: "break-all",color:themeData?.font_color }}>
      {description}
      {trim && (
        <span style={{ marginLeft: "2px", fontWeight: 500 }}>...readmore</span>
      )}
    </Typography>
  );
};

export default ItemDescription;
