import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccordionItem from "./AccordionItem";
import { Box } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Accordion = (props, ref) => {
  const [expanded, setExpanded] = React.useState(true);
  //details modal
  const [toggleDetails, setToggleDetails] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box ref={ref} sx={{mt:0}}>
    <Card sx={{ boxShadow: "none",borderRadius:'0px',pt:1,backgroundColor:props?.themeData?.theme_color }} >
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{ cursor: "pointer" }}
      >
        <Typography sx={{ fontWeight: 600,fontSize:'13px',color:props?.themeData?.font_color }}>
          {props?.label||'--'} ({props?.count})
        </Typography>
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ArrowDropDownIcon sx={{ fontSize: 26, color: "black" }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AccordionItem
          data={props?.data}
          toggleDetails={toggleDetails}
          setToggleDetails={setToggleDetails}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          themeData={props?.themeData}
        />
      </Collapse>
    </Card>
    </Box>
  );
};

export default React.forwardRef(Accordion);
