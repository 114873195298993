import React from "react";
import { Chip } from "@mui/material";

const FeaturedChip = ({ label, unavailable }) => {
  return (
    <Chip
      label={label}
      style={{
        backgroundColor: unavailable ? "gray" : "#EB6B3C",
        borderRadius: "4px",
        color: "white",
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: 400,
        boxShadow: "none",
        height: "90%",
      }}
    />
  );
};

export default FeaturedChip;
