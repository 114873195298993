import React from "react";
import {
  Grid,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  List,
  SwipeableDrawer,
  Box,
  Skeleton,
  Typography,
  Button,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { WhatsApp } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";

import FeaturedRow from "./FeaturedRow";
import ItemDetailsImage from "./ItemDetailsImage";
import ItemTitle from "./ItemTitle";
import ItemRating from "./ItemRating";
import ItemPrice from "./ItemPrice";
import ItemDescription from "./ItemDescription";
import FeaturedImage from "./FeaturedImage";

import { useParams } from "react-router-dom";

import StyledRating from "./StyledRating";

import fetchSubMenuList from "../../api/fetchSubMenuList";
import { fetchMenuList } from "../../api/fetchMenuList";
import postRating from "../../api/postRating";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { useQuery, useMutation } from "react-query";

import fetchResturentInfo from "../../api/fetchResturentInfo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ItemDetails({
  selectedID,
  state,
  toggleDrawer,
  selectedData,
  utilImages,
  themeData,
}) {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const [index, setIndex] = React.useState(-1);

  const { username } = useParams();

  const [user_type] = React.useState(
    localStorage.getItem("pwa_user_type") || null
  );

  const { data: resturentInfo } = useQuery(["resturent-info"], () =>
    fetchResturentInfo({ username })
  );

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isLoading } = useQuery(
    ["subMenuList", selectedID],
    () => fetchSubMenuList({ selectedID, username }),
    {
      enabled: !!selectedID,
      username: username !== "auto_elec",
    }
  );

  const { refetch } = useQuery(
    ["menu-list", fetchMenuList],
    () => fetchMenuList({ username }),
    {
      enabled: user_type !== null && user_type !== "auto_elec",
    }
  );

  const {
    mutate,
    isSuccess: postRatingSuccess,
    isError: postRatingError,
    error: postRatingErrorMessage,
  } = useMutation(["postRating"], ({ id, rating }) => {
    return postRating({ selectedID: id, rating });
  });

  const handleRating = (event, id) => {
    mutate({ id, rating: event.target.value });
  };

  React.useEffect(() => {
    if (postRatingSuccess) {
      handleClick();
      toggleDrawer(false);
      refetch();
    }
    if (postRatingError) {
      handleClickError();
    }
  }, [postRatingSuccess, postRatingError]);

  let slides = selectedData?.product_image?.map((item, idx) => ({
    src: item,
    key: idx,
    id: idx,
    width: "100%",
    height: "100%",
  }));

  if (slides?.length > 0) {
    slides = [
      ...slides,
      {
        src: selectedData?.image,
        key: slides?.length,
        id: slides?.length,
        width: "100%",
        height: "100%",
      },
    ];
  } else {
    slides = [
      {
        src: selectedData?.image,
        key: 0,
        id: 0,
        width: "100%",
        height: "100%",
      },
      {
        src: selectedData?.image,
        key: 0,
        id: 0,
        width: "100%",
        height: "100%",
      },
    ];
  }

  const list = () => (
    <Box
      sx={{
        overflow: "hidden",
        backgroundColor: themeData?.theme_color,
        color: themeData?.font_color,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
      />

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ marginBottom: 5 }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Rating added successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        sx={{ marginBottom: 5 }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {postRatingErrorMessage?.response?.data?.message}
        </Alert>
      </Snackbar>
      <Grid container minHeight={100} spacing={2} margin={3}>
        <Grid item xs={4} onClick={() => setIndex(1)}>
          <ItemDetailsImage image={selectedData.image} />
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={0.5}>
            {user_type !== "auto_elec" && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FeaturedRow
                    topper={selectedData.is_chart_topper}
                    image={
                      selectedData.food_type === "non-veg"
                        ? utilImages.nonveg
                        : utilImages.veg
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <ItemTitle title={selectedData.name} themeData={themeData} />
            </Grid>
            {user_type !== "auto_elec" && (
              <Grid item xs={12}>
                <Grid container>
                  <ItemRating
                    rating={selectedData.rating}
                    review_count={selectedData.rating_count}
                    themeData={themeData}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <ItemPrice price={selectedData.price} themeData={themeData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <ItemDescription description={selectedData.description} themeData={themeData} />
          </Grid>
        </Grid>
      </Grid>
      {user_type !== "auto_elec" && (
        <List sx={{ m: 2 }} dense>
          {user_type !== "auto_elec" &&
            !isLoading &&
            data?.data?.results?.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                secondaryAction={
                  <ListItemText
                    edge="end"
                    primary={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          color: themeData?.font_color,
                        }}
                      >
                        ₹{item.price}
                      </Typography>
                    }
                  />
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <FeaturedImage
                      image={
                        item.food_type === "non-veg"
                          ? utilImages.nonveg
                          : utilImages.veg
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          color: themeData?.font_color,
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          {user_type !== "auto_elec" && isLoading && (
            <>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", mx: 3 }}
                width={"90%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", mx: 3 }}
                width={"90%"}
              />
            </>
          )}
        </List>
      )}
      {user_type !== "auto_elec" && (
        <Grid container sx={{ m: 2 }}>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <StyledRating themeData={themeData} handleRating={handleRating} selectedID={selectedID} />
          </Grid>
        </Grid>
      )}
      {user_type === "auto_elec" && (
        <Grid
          container
          xs={11}
          columnSpacing={2}
          style={{
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          <Grid item xs={6}>
            {resturentInfo?.result?.whatsapp_number?.length > 11 ? (
              <a
                href={`https://wa.me/${
                  resturentInfo?.result?.whatsapp_number
                }/?text=${encodeURIComponent(
                  `Hi, I want to know more about ${selectedData.name} . `
                )}`}
              >
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<WhatsApp />}
                  sx={{
                    backgroundColor: themeData?.theme_color,
                    color: themeData?.font_color,
                    textTransform: "none",
                    padding: "10px 14px",
                    borderRadius: "24px",
                  }}
                >
                  Know More
                </Button>
              </a>
            ) : (
              <a
                href={`https://wa.me/+91${
                  resturentInfo?.result?.whatsapp_number
                }/?text=${encodeURIComponent(
                  `Hi, I want to know more about ${selectedData.name} . `
                )}`}
              >
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<WhatsApp />}
                  sx={{
                    backgroundColor: themeData?.theme_color,
                    color: themeData?.font_color,
                    textTransform: "none",
                    padding: "10px 14px",
                    borderRadius: "24px",
                  }}
                >
                  Know More
                </Button>
              </a>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<ShareIcon />}
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: selectedData.name,
                      text: `Check out ${selectedData.name} at ${selectedData.price}`,
                      url: `https://catzify.com/${username}`,
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
                }
              }}
              sx={{
                backgroundColor: themeData?.theme_color,
                color: themeData?.font_color,
                textTransform: "none",
                padding: "10px 14px",
                borderRadius: "24px",
              }}
            >
              Share
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor={"bottom"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        draggable
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "white",
            borderRadius: "24px 24px 0px 0px",
          },
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default React.memo(ItemDetails);
