import api from "./api.js";

const fetchResturentInfo = async ({ username }) => {
  const response = await api.actionHandler({
    method: "get",
    url: api.resturentInfoDetailsURL.replace("{username}", username),
  });

  localStorage.setItem("pwa_user_type", response?.data?.result?.user_type);

  return response.data;
};

export default fetchResturentInfo;
