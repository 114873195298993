import api from "./api.js";

const postRating = async ({ selectedID, rating, username }) => {
  const response = await api.actionHandler({
    method: "post",
    url: api.ratingPostURL
      .replace("{id}", selectedID)
      .replace("{username}", username),
    data: { rating },
  });

  return response.data;
};

export default postRating;
