import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

export default function MenuDialog({
  title,
  open,
  setOpen,
  options,
  selected,
  handleChipSelect,
  themeData
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (name) => {
    handleChipSelect(name);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 435,
            borderRadius: "32px",
            paddingBlock: "8px",
            backgroundColor: themeData?.theme_color,
            color: themeData?.font_color,
          },
        }}
        open={open}
        hideBackdrop
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>{title}</DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <List dense>
            {options?.result.map((option) => {
              return (
                <ListItem
                  sx={{
                    textDecoration:
                      selected === option.name ? "underline" : "none",
                    fontWeight: selected === option.name ? 800 : 400,
                  }}
                  onClick={() => handleSelect(option.name)}
                  secondaryAction={<Typography>{option.count}</Typography>}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 400,color:themeData?.font_color }}>
                        {option.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
