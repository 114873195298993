import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

export default function QRCodeModal({ open, setOpen }) {
  const { username } = useParams();
  const handleClose = () => {
    setOpen(false);
  };

  const downloadQR = async () => {
    const canvas = await html2canvas(document.getElementById("HpQrcode"));
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  };

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 435,
            borderRadius: "32px",
            paddingBlock: "8px",
          },
        }}
        open={open}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle sx={{ fontWeight: 600, textAlign: "center" }}>
          {"Scan QR Code"}
        </DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <div
            id="HpQrcode"
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: "70%",
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`https://catzify.com/${username}`}
              viewBox={`0 0 256 256`}
              level={"H"}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              cursor: "pointer",
            }}
          >
            <Button onClick={downloadQR}>
              <CloudDownloadIcon />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
