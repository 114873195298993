import { Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)({
  background: "#000",
  border: 0,
  borderRadius: 12,
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  width: "100%",
  "& .MuiButton-label": {
    textTransform: "none",
  },
  "&:active": {
    background: "#000",
  },
});

const StyledSubmit = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default StyledSubmit;
