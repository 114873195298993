import food from "../../assets/utils/food2.png";

const ItemDetailsImage = ({ image }) => {
  return (
    <>
      {image ? (
        <img
          src={`${image}`}
          alt={"Chicken Biriyani"}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100px",
            borderRadius: "8px",
          }}
          loading="lazy"
        />
      ) : (
        <img
          src={food}
          alt={"Chicken Biriyani"}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "120px",
            borderRadius: "8px",
          }}
          loading="lazy"
        />
      )}
    </>
  );
};

export default ItemDetailsImage;
