import api from "./api.js";

const fetchMenuList = async ({ username, user_type }) => {
  if (user_type && user_type !== "auto_elec") {
    const response = await api.actionHandler({
      method: "get",
      url: api.menuListURL.replace("{username}", username),
    });

    return response.data;
  }
};

const fetchProductList = async (username, user_type) => {
  if (user_type && user_type !== "restaurant") {
    const response = await api.actionHandler({
      method: "get",
      url: api.productListURL.replace("{username}", username),
    });

    return response.data;
  }
};

const fetchRecommended = async ({ username, user_type }) => {
  if (user_type && user_type !== "auto_elec") {
    const response = await api.actionHandler({
      method: "get",
      url: api.menuListURL.replace("{username}", username) + `&category=rec`,
    });

    return response.data;
  }
};

export { fetchMenuList, fetchProductList, fetchRecommended };
