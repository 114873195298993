import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { WhatsApp } from "@mui/icons-material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import ForumIcon from "@mui/icons-material/Forum";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import fetchResturentInfo from "./api/fetchResturentInfo";

import "./components/buttonStyle.css";

import logo2 from "./assets/logo/logo2.png";

import StyledInput from "./components/controls/TextField";
import StyledTextArea from "./components/controls/StyledTextArea";
import StyledSubmit from "./components/controls/StyledSubmit";

import { useMutation, useQuery } from "react-query";

import { useParams, useLocation, useHistory } from "react-router-dom";

import postFeedback from "./api/postFeedback";

import { toast } from "react-hot-toast";

import Dialer from "./assets/logo/Dialer.svg";
import Whatsapp from "./assets/logo/Whatsapp.svg";
import Mail from "./assets/logo/Mail.svg";
import Web from "./assets/logo/Web.svg";
import Map from "./assets/logo/Map.svg";
import Review from "./assets/logo/Review.svg";

import one from "./assets/logo/small/1.svg";
import two from "./assets/logo/small/2.svg";
import three from "./assets/logo/small/3.svg";
import four from "./assets/logo/small/4.svg";
import five from "./assets/logo/small/5.svg";

import QRCodeModal from "./components/QRCodeModal";
import fetchThemeSetting from "./api/fetchThemeSetting";

export default function MainAboutUs() {
  const location = useLocation();
  const history = useHistory();

  const options = {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
  };

  const { data: resturentInfo } = useQuery(
    ["resturent-info"],
    () => fetchResturentInfo({ username }),
    {
      options,
    }
  );

  const { username } = useParams();

  const { data: themeSetting } = useQuery(
    ["theme-setting"],
    () => fetchThemeSetting({ username }),
    {
      options,
    }
  );

  const [toggleFeedback, setToggleFeedback] = useState(false);
  const [toggleAbout, setToggleAbout] = useState(false);
  const [toggleQr, setToggleQr] = useState(false);

  const [sharenum, setSharenum] = useState("");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [messageErr, setMessageErr] = useState("");

  const {
    mutate: postFeedbackMutation,
    isSuccess: postFeedbackSuccess,
    isError: postFeedbackError,
    error: postFeedbackErrorMessage,
  } = useMutation(["postFeedback"], ({ payload }) => {
    return postFeedback({ payload });
  });

  const handleName = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const handleMobile = (event) => {
    event.preventDefault();
    setMobile(event.target.value);
  };

  const handleMessage = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const handleSharenum = (event) => {
    event.preventDefault();
    setSharenum(event.target.value);
  };

  const getUrl = () => {
    //get second part
    let url = window.location.href
    let url2 = url.split('/')
    return url2[3]?.toUpperCase()||''
  }

  //get url

  const saveContact = () => {
    let contact = {
      name: resturentInfo?.result?.name || getUrl(),
      phone: resturentInfo?.result?.mobile || "1234567890",
    };
    let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN:${contact.name}\nTEL;TYPE=WORK,VOICE:${contact.phone}\nEND:VCARD`;
    let blob = new Blob([vcard], { type: "text/vcard" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");

    a.style = "display: none";

    a.href = url;
    a.download = `${contact.name}.vcf`;

    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);

    a.remove();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "") {
      setNameErr("Name is required");
    } else if (mobile === "") {
      setMobileErr("Mobile is required");
    } else if (mobile.length > 12 || mobile.length < 10) {
      setMobileErr("Mobile number must be 10-12 digits");
    } else if (message === "") {
      setMessageErr("Message is required");
    } else {
      setNameErr("");
      setMobileErr("");
      setMessageErr("");
      let payload = {
        name,
        mobile,
        message,
        username,
      };
      postFeedbackMutation({ payload });
    }
  };

  useEffect(() => {
    if (postFeedbackSuccess) {
      setName("");
      setMobile("");
      setMessage("");
      toast.success("Feedback added successfully");
      setToggleFeedback(false);
    } else if (postFeedbackError) {
    }
  }, [postFeedbackSuccess, postFeedbackError, postFeedbackErrorMessage]);

  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const list = () => (
    <Box sx={{ overflow: "hidden", width: "100%",height:"100vh" ,backgroundColor:themeSetting?.data?.theme_color,}} role="presentation">
      <Grid
        style={{
          position: "relative",
          backgroundImage: `url(${
            resturentInfo?.result?.cover_picture?.length > 0
              ? resturentInfo?.result?.cover_picture
              : "https://bdine-bucket.s3.ap-south-1.amazonaws.com/bdine/background.jpg"
          })`,
          backgroundRepeat: "no-repeat",
          objectFit: "fill",
        }}
        container
        minHeight={210}
        spacing={0}
      >
        <Grid
          style={{
            position: "absolute",
            top: "65%",
            left: "35%",
          }}
        >
          <Avatar
            // src={logo2}
            src={
              resturentInfo?.result?.profile_picture?.length > 0
                ? resturentInfo?.result?.profile_picture
                : logo2
            }
            sx={{
              width: 120,
              height: 120,
              border: "5px solid #fff",
              padding:
                resturentInfo?.result?.profile_picture?.length > 0 ? 0 : 3,
              backgroundColor: "#fff",
            }}
          />
        </Grid>
        {resturentInfo?.result?.toggle_info && (
          <Grid
            style={{
              position: "absolute",
              top: 15,
              right: "5%",
              color: "white",
              cursor: "pointer",
            }}
          >
            <InfoOutlinedIcon
              className="animatedbutton"
              onClick={(e) => setToggleAbout(true)}
            />
          </Grid>
        )}
      </Grid>

      <Typography
        sx={{
          marginTop: 8,
          fontSize: 22,
          fontWeight: "bold",
          color:themeSetting?.data?.font_color,
          textAlign: "center",
        }}
      >
        {username?.toUpperCase() || "Plan B"}
      </Typography>

      <Grid
        container
        marginTop={2}
        flexWrap="none"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Grid item>
          {resturentInfo?.result?.mobile?.length > 0 ? (
            <a href={`tel:+${resturentInfo?.result?.mobile}`}>
              <img
                src={Dialer}
                style={{ width: "45px", height: "45px" }}
                alt="phone"
              />
            </a>
          ) : (
            <img
              src={Dialer}
              style={{ width: "45px", height: "45px" }}
              alt="phone"
            />
          )}
        </Grid>
        <Grid item>
          {(resturentInfo?.result?.whatsapp_number?.length > 0 &&
            resturentInfo?.result?.whatsapp_number?.length) > 11 ? (
            <a href={`https://wa.me/${resturentInfo?.result?.whatsapp_number}`}>
              <img
                src={Whatsapp}
                style={{ width: "45px", height: "45px" }}
                alt="whatsapp"
              />
            </a>
          ) : (resturentInfo?.result?.whatsapp_number?.length > 0 &&
              resturentInfo?.result?.whatsapp_number?.length) < 11 ? (
            <a
              href={`https://wa.me/+91${resturentInfo?.result?.whatsapp_number}`}
            >
              <img
                src={Whatsapp}
                style={{ width: "45px", height: "45px" }}
                alt="whatsapp"
              />
            </a>
          ) : (
            <img
              src={Whatsapp}
              style={{ width: "45px", height: "45px" }}
              alt="whatsapp"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.google_map?.length > 0 ? (
            <a href={resturentInfo?.result?.google_map}>
              <img
                src={Map}
                style={{ width: "45px", height: "45px" }}
                alt="map"
              />
            </a>
          ) : (
            <img
              src={Map}
              style={{ width: "45px", height: "45px" }}
              alt="map"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.email?.length > 0 ? (
            <a href={`mailto:${resturentInfo?.result?.email}`}>
              <img
                src={Mail}
                style={{ width: "45px", height: "45px" }}
                alt="mail"
              />
            </a>
          ) : (
            <img
              src={Mail}
              style={{ width: "45px", height: "45px" }}
              alt="mail"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.website?.length > 0 ? (
            <a href={resturentInfo?.result.website}>
              <img
                src={Web}
                style={{ width: "45px", height: "45px" }}
                alt="web"
              />
            </a>
          ) : (
            <img
              src={Web}
              style={{ width: "45px", height: "45px" }}
              alt="web"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.google_review?.length > 0 ? (
            <a href={resturentInfo?.result.google_review}>
            <div style={{ width: "45px", height: "45px",backgroundColor:'#8B93FF',borderRadius:'90%',display:'flex',justifyContent:'center',alignItems:'center' }}>
             <img
                src={Review} 
                style={{ width: "20px", height: "20px" }}
                alt="web"
              />
             </div>
            </a>
          ) : (
            <div style={{ width: "45px", height: "45px",backgroundColor:'#8B93FF',borderRadius:'90%',display:'flex',justifyContent:'center',alignItems:'center' }}>
             <img
                src={Review} 
                style={{ width: "20px", height: "20px" }}
                alt="web"
              />
             </div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} paddingInline={2} marginTop={2}>
        <Grid item xs={8}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            value={sharenum}
            onChange={handleSharenum}
            placeholder="Enter to share via Whatsapp"
            InputProps={{
              startAdornment: 91,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                border: `1px solid ${themeSetting?.data?.font_color}`,
                color: themeSetting?.data?.font_color,
              },
              "& .MuiOutlinedInput-input": {
                padding: "10px 14px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: themeSetting?.data?.font_color,
              },
              color:themeSetting?.data?.font_color,

            }}
          />
        </Grid>
        <Grid item xs={4}>
          <a
            href={`https://wa.me/${"+91" + sharenum}/?text=
          ${encodeURIComponent(
            `Hey, I found this amazing shop. Check it out: ${window.location.href}`
          )}
          `}
          >
            <Button
              variant="contained"
              fullWidth
              startIcon={<WhatsApp />}
              sx={{
                backgroundColor:themeSetting?.data?.theme_color,
                textTransform: "none",
                padding: "10px 14px",
                borderRadius: "24px",
                color:themeSetting?.data?.font_color,
                border: `1px solid ${themeSetting?.data?.font_color}`,

              }}
            >
              Share
            </Button>
          </a>
        </Grid>
      </Grid>

      <Grid container spacing={1} paddingInline={2} marginTop={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddIcCallIcon />}
            sx={{
              backgroundColor:themeSetting?.data?.theme_color,
              textTransform: "none",
              padding: "10px 14px",
              borderRadius: "24px",
              color:themeSetting?.data?.font_color,
              border: `1px solid ${themeSetting?.data?.font_color}`,

            }}
            onClick={() => saveContact()}
          >
            Add to Contacts
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setToggleQr(true)}
            startIcon={<QrCode2OutlinedIcon />}
            sx={{
              backgroundColor:themeSetting?.data?.theme_color,
              textTransform: "none",
              padding: "10px 14px",
              borderRadius: "24px",
              color:themeSetting?.data?.font_color,
              border: `1px solid ${themeSetting?.data?.font_color}`,
            }}
          >
            QR Code
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        paddingInline={5}
        marginTop={2}
        display="flex"
        justifyContent="center"
        flexWrap="none"
      >
        <Grid item>
          {resturentInfo?.result?.facebook?.length > 0 ? (
            <a href={resturentInfo?.result?.facebook}>
              <img
                src={one}
                style={{ width: "31px", height: "31px" }}
                alt="facebook"
              />
            </a>
          ) : (
            <img
              src={one}
              style={{ width: "31px", height: "31px" }}
              alt="facebook"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.whatsapp_number?.length > 0 ? (
            <a href="https://in.pinterest.com/">
              <img
                src={two}
                style={{ width: "31px", height: "31px" }}
                alt="pintereset"
              />
            </a>
          ) : (
            <img
              src={two}
              style={{ width: "31px", height: "31px" }}
              alt="pintereset"
            />
          )}
        </Grid>
        <Grid item>
          {resturentInfo?.result?.instagram?.length > 0 ? (
            <a href={resturentInfo?.result?.instagram}>
              <img
                src={three}
                style={{ width: "31px", height: "31px" }}
                alt="instagram"
              />
            </a>
          ) : (
            <img
              src={three}
              style={{ width: "31px", height: "31px" }}
              alt="instagram"
            />
          )}
        </Grid>
        <Grid item>
          <a href="https://www.linkedin.com/home?originalSubdomain=in">
            <img
              src={four}
              style={{ width: "31px", height: "31px" }}
              alt="linkedin"
            />
          </a>
        </Grid>
        <Grid item>
          <a href="https://twitter.com/login?lang=en">
            <img
              src={five}
              style={{ width: "31px", height: "31px" }}
              alt="twitter"
            />
          </a>
        </Grid>
      </Grid>

      {location.pathname.includes("aboutus") && (
        <Grid
          container
          minHeight={50}
          spacing={0}
          marginTop={2}
          marginBottom={2}
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={4}>
            <Button
              onClick={() => history.push(`/${username}`)}
              className="animatedbutton"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor:themeSetting?.data?.theme_color,
                color: themeSetting?.data?.font_color,
                textTransform: "none",
                padding: "10px 14px",
                borderRadius: "24px",
              }}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );

  const feedback = () => (
    <Box sx={{ overflow: "hidden", width: "100%" }} role="presentation">
      <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "start" }}>
          <IconButton
            aria-label="back-to-menu"
            onClick={() => {
              setToggleFeedback(false);
              setToggleAbout(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "start", marginTop: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 18, marginLeft: 1, color:themeSetting?.data?.font_color }}>
            Feedback
          </Typography>
        </Grid>
      </Grid>
      <Grid container minHeight={100} rowSpacing={3} marginX={2} marginTop={1}>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledInput
            label="Name"
            value={name}
            onChange={handleName}
            error={nameErr}
            setErr={setNameErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledInput
            label="Mobile Number"
            value={mobile}
            error={mobileErr}
            type="number"
            onChange={handleMobile}
            setErr={setMobileErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledTextArea
            label="Type your suggestions here"
            value={message}
            error={messageErr}
            onChange={handleMessage}
            setErr={setMessageErr}
          />
        </Grid>
        <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
          <StyledSubmit onClick={handleSubmit}>Submit</StyledSubmit>
        </Grid>
      </Grid>
    </Box>
  );

  const basic = () => {
    return (
      <>
        <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "start" }}>
            <IconButton
              aria-label="back-to-menu"
              onClick={() => setToggleAbout(false)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", alignItems: "start", marginTop: 1 }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 18, marginLeft: 1 }}>
              More Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container minHeight={100} spacing={0} marginX={3}>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "start",
              marginTop: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              {resturentInfo?.result?.address?.length > 0 ? (
                <a
                  href={resturentInfo?.result?.google_map}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#5970E4" }}
                >
                  {resturentInfo?.result?.address}
                </a>
              ) : (
                "No address found"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container minHeight={50} spacing={0} marginTop={2} marginX={2}>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", alignItems: "start", mt: 1.5 }}
          >
            <IconButton aria-label="back-to-menu">
              <AccessTimeIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              alignItems: "start",
              marginTop: 1,
              marginLeft: 1,
            }}
          >
            {resturentInfo?.result?.working_days?.length > 0 ? (
              <List sx={{ width: "100%" }}>
                {resturentInfo?.result?.working_days?.map((_, idx) => {
                  return (
                    <div key={idx}>
                      {!_.is_holiday && (
                        <ListItem
                          disablePadding
                          secondaryAction={
                            <ListItemText
                             sx={{mt:2}}
                              edge="end"
                              primary={
                                 <Typography sx={{ fontWeight: 500, fontSize: 13 }}>
                                  {tConvert(_.start_time.slice(0, 5)) +"    -    " +
                                    tConvert(_.end_time.slice(0, 5))}
                                  </Typography>                              
                              }
                              secondary={
                                <Typography sx={{ fontWeight: 500, fontSize: 13 }}>
                                  {(_?.second_start_time&&_?.second_end_time)?tConvert(_?.second_start_time?.slice(0, 5)) +"    -    " +
                                    tConvert(_?.second_end_time?.slice(0, 5)):'No second shift'}
                                </Typography>
                              }
                            />
                          }
                        >
                          <ListItemText
                          sx={{mt:2}}
                            primary={
                              <Typography
                                sx={{ fontWeight: 500, fontSize: 13 }}
                              >
                                {_.day.charAt(0).toUpperCase() + _.day.slice(1)}
                              </Typography>
                            }
                          />
                        </ListItem>
                      )}
                    </div>
                  );
                })}
              </List>
            ) : (
              "No schedule found"
            )}
          </Grid>
        </Grid>
        <Grid
          container
          minHeight={50}
          spacing={0}
          marginTop={2}
          marginBottom={2}
          display="flex"
          justifyContent="center"
          onClick={() => {
            setToggleAbout(false);
            setToggleFeedback(true);
          }}
        >
          <Grid
            item
            xs={2}
            sx={{ display: "flex", alignItems: "start", mt: 1.5 }}
          >
            <IconButton aria-label="back-to-menu">
              <ForumIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              alignItems: "start",
              marginTop: 2.5,
              marginLeft: 1,
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              Feedback or Suggestions
            </Typography>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "start",
              marginTop: 2.5,
            }}
          >
            <ChevronRightIcon />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {!toggleAbout && !toggleFeedback && list()}
      {!toggleAbout && toggleFeedback && feedback()}
      {toggleAbout && basic()}
      {toggleQr && <QRCodeModal open={toggleQr} setOpen={setToggleQr} />}
    </>
  );
}
