import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 500) {
      return Promise.reject(error);
    }
  }
);

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://backend.catzify.com/api_bdine_backend/api";

    axios(payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menuListURL: "/core/{username}/menus/?limit=1000",
  productListURL: "/core/{username}/products/list/?limit=1000",
  subMenuListURL: "/core/{username}/menu/{id}/submenus/?limit=1000",
  categoriesListURL: "/core/{username}/categories/?limit=1000",
  subCategoriesListURL: "/core/{username}/categories/{id}/subcategories/?limit=1000",
  ratingPostURL: "/core/{username}/menu/{id}/rating/",
  feedbackPostURL: "/core/{username}/feedback/",
  resturentInfoDetailsURL: "/core/{username}/restaurant/info/",
  imagesListURL: "/restaurant/profile_picture",
  menuButtonListURL: "/core/{username}/menu_buttons/list/",
  themeSettingURL: "/core/{username}/font_and_color/",
  actionHandler,
};
