import React from "react";
import { Grid } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";


const ChipsList = ({ categories, selected, handleChipSelect, menuList,themeData,setSelectedCategory }) => {

  const CustomChip = styled(Chip)({
    [`&.${chipClasses.filled}`]: {
      backgroundColor: 'black',
      fontSize:'12px !important'
  
    },
  });
  return (
    <Grid
      container
      paddingX={3}
      paddingY={1}
      gap={1}
      display="flex"
      flexWrap="nowrap"
      maxWidth="100%"
      sx={{
        pt: 3,
        overflowX: "auto",
        backgroundColor:themeData?.theme_color,
        paddingBottom: 1,
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {categories?.result.map(
        (chip, i) =>
          chip?.name?.toLowerCase() !=='recommended' && (
            <Grid item key={i}>
              <CustomChip
                label={chip.name}
                variant={selected === chip.name ? "filled" : "outlined"}
                sx={{
                  border: "1px solid #CACACA",
                  color: selected === chip.name ?"white"  : themeData?.font_color,
                  
                }}
                onClick={() => {
                  handleChipSelect(chip.name)
                  setSelectedCategory(chip.id)
                }}
              />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default ChipsList;
