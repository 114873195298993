import api from "./api.js";

const fetchSubCategories = async ({ username ,categoryID}) => {
    console.log("username",username)
    console.log("categoryID",categoryID)
  const response = await api.actionHandler({
    method: "get",
    url: api.subCategoriesListURL.replace("{username}", username).replace("{id}", categoryID)
  });

  return response.data;
};

export default fetchSubCategories;
