import { Grid, Toolbar, AppBar } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

import { useParams, useHistory } from "react-router-dom";

import fetchResturentInfo from "../../api/fetchResturentInfo";

import { useQuery } from "react-query";

const Index = ({themeData}) => {
  const { username } = useParams();
  const history = useHistory();

  const CustomChip = styled(Chip)({
    [`&.${chipClasses.filled}`]: {
      backgroundColor: themeData?.button_color,
      color: themeData?.button_text_color,
      fontSize:'13px'
    },
  });

  const { data: resturentInfo } = useQuery(["resturent-info"], () =>
    fetchResturentInfo({ username })
  );

  return (
    <AppBar sx={{ boxShadow: "none", position: "relative",backgroundColor:themeData?.theme_color,}}>
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingInline: 2, paddingBlock: 2 }}
        >
          <Grid xs={6} xl={6} item>
            {resturentInfo?.result?.photo?.length > 0 && (
              <img
                src={
                  resturentInfo?.result?.photo?.length > 0
                    ? resturentInfo?.result?.photo
                    : ""
                }
                height={
                  resturentInfo?.result?.photo?.length > 0 ? "90px" : "50px"
                }
                objectFit="fill"
                loading="lazy"
                width={
                  resturentInfo?.result?.photo?.length > 0 ? "90px" : "70px"
                }
                alt="logo"
              />
            )}
          </Grid>
          <Grid
            xs={6}
            xl={6}
            item
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems="center"
            textAlign={"Center"}
          >
            <CustomChip
              onClick={() => history.push(`/${username}/aboutus`)}
              label="About Us"
              variant="filled"
              sx={{
                border: "1px solid #CACACA",
                color: themeData?.font_color,
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Index;
