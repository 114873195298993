import React from "react";
import { CardContent, Grid } from "@mui/material";

import FeaturedRow from "./itemcomponents/FeaturedRow";
import FeaturedChip from "./itemcomponents/FeaturedChip";
import FeaturedImage from "./itemcomponents/FeaturedImage";
import ItemImage from "./itemcomponents/ItemImage";
import ItemTitle  from "./itemcomponents/ItemTitle";
import ItemRating from "./itemcomponents/ItemRating";
import ItemPrice from "./itemcomponents/ItemPrice";
import ItemDescription from "./itemcomponents/ItemDescription";
import ItemDetails from "./itemcomponents/ItemDetails";

import VideoModal from "./VideoModal";

import nonveg from "../assets/utils/nonveg.svg";
import veg from "../assets/utils/veg.svg";
import youtube from "../assets/utils/youtube.svg";

const AccordionItem = ({
  data,
  toggleDetails,
  setToggleDetails,
  selectedID,
  setSelectedID,
  themeData
}) => {
  const [toggleVideo, setToggleVideo] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);
  const [selectedItem ,setSelectedItem] = React.useState({});

  const [user_type] = React.useState(
    localStorage.getItem("pwa_user_type") || null
  );

  const toggleDrawer = (item, open) => (event) => {
    setSelectedID(item.id);
    setSelectedItem(item)
    if (event &&event.type === "keydown" &&(event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setToggleDetails(open);
  };

  const toggleVideoURL = (url) => {
    setVideoURL(url);
    setToggleVideo(true);
  };

  return (
    <>
      {data.map((item, idx) => {
        return (
          <CardContent key={idx}>
            <Grid container minHeight={160} spacing={2}>
              <Grid
                item
                xs={5}
                md={2}
                onClick={item.is_available ? toggleDrawer(item, true) : ""}
              >
                <ItemImage image={item.image} status={item.is_available} />
              </Grid>
              <Grid item xs={6}>
                <Grid container rowSpacing={0.5}>
                  {user_type !== "auto_elec" && (
                    <Grid
                      item
                      xs={12}
                      onClick={
                        item.is_available ? toggleDrawer(item, true) : ""
                      }
                    >
                      <Grid container spacing={2}>
                        <FeaturedRow
                          topper={item.is_chart_topper}
                          image={item.food_type === "non-veg" ? nonveg : veg}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item, true) : ""
                    }
                  >
                    <ItemTitle themeData={themeData} title={item.name} />
                  </Grid>
                  {user_type !== "auto_elec" && (
                    <Grid
                      item
                      xs={12}
                      onClick={
                        item.is_available ? toggleDrawer(item, true) : ""
                      }
                    >
                      <Grid container>
                        <ItemRating
                          rating={item.rating || null}
                          review_count={item.rating_count}
                          themeData={themeData}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {item.is_available ? (
                    <Grid item xs={12} onClick={toggleDrawer(item, true)}>
                      <ItemPrice themeData={themeData} price={item.price} />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <FeaturedChip label="Not Available" unavailable={true} />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    onClick={
                      item.is_available ? toggleDrawer(item, true) : ""
                    }
                  >
                    <ItemDescription
                      description={item.description.substring(
                        0,
                        item.yt_video ? 12 : 60
                      )}
                      trim
                      themeData={themeData}
                    />
                  </Grid>
                  {item.yt_video && (
                    <Grid
                      item
                      xs={12}
                      mt={1}
                      onClick={
                        item.is_available
                          ? () => toggleVideoURL(item.yt_video)
                          : ""
                      }
                    >
                      <FeaturedImage image={youtube} url={item.yt_video} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        );
      })}
      {data.length === 0 && (
        <CardContent>
          <Grid container minHeight={160} spacing={2}>
            <Grid item xs={12}>
              <ItemDescription themeData={themeData} description="No items found" />
            </Grid>
          </Grid>
        </CardContent>
      )}
      {toggleDetails&&<ItemDetails
        selectedID={selectedID}
        state={toggleDetails}
        toggleDrawer={toggleDrawer}
        selectedData={selectedItem || {}}
        utilImages={{ nonveg, veg }}
        themeData={themeData}
      />}
      <VideoModal open={toggleVideo} setOpen={setToggleVideo} url={videoURL} />
    </>
  );
};

export default React.memo(AccordionItem);
