import * as React from "react";
import { Grid, Fab } from "@mui/material";
import { Box, styled } from "@mui/system";

import Header from "./components/header/Index";
import HeroCarousel from "./components/Carousel";
import MenuDialog from "./components/MenuModal";

import CustomSearchInput from "./components/controls/Index";

import MainAboutUs from "./MainAboutUS";

import { useParams } from "react-router-dom";

import ChipsList from "./components/ChipsList";
import TypeChipsList from "./components/TypeChipList";
import FoodAccordion from "./components/Accordion";
import Skeleton from "@mui/material/Skeleton";

import {
  fetchMenuList,
  fetchProductList,
  fetchRecommended,
} from "./api/fetchMenuList";
import fetchCategories from "./api/fetchCategories";
import fetchResturentInfo from "./api/fetchResturentInfo";
import fetchMenuButtons from "./api/fetchMenuButtom";
import fetchThemeSetting from "./api/fetchThemeSetting";
import noresults from "./assets/utils/no-results.png";

import { useQuery } from "react-query";

import product from "./assets/utils/product2.png";
import close from "./assets/utils/close.svg";

import reactManifest from "react-manifest";
import SubChipsList from "./components/SubChipList";
import fetchSubCategories from "./api/fetchSubCategories";

const options = {
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
};

function App() {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [selectedChip, setSelectedChip] = React.useState("Recommended");
  const [selectedType, setSelectedType] = React.useState("all");
  const [selectedCategory, setSelectedCategory] = React.useState("0");
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(null);

  const [user_type, setUserType] = React.useState(
    localStorage.getItem("pwa_user_type")
  );

  const [itemsLoading, setItemsLoading] = React.useState(false);

  const [Search, setSearch] = React.useState("");

  const { username } = useParams();

  const ref = React.useRef(null);

  const [menuList, setMenuList] = React.useState([]);

  const { data: resturentInfo } = useQuery(
    ["resturent-info"],
    () => fetchResturentInfo({ username }),
    {
      options,
    }
  );

  const { data, isLoading } = useQuery(
    [
      "menu-list",
      fetchMenuList,
      {
        ...options,
        enabled: !!(!!(user_type !== null) && !!(user_type !== "auto_elec")),
      },
    ],
    () => fetchMenuList({ username, user_type })
  );

  React.useEffect(() => {
    if (resturentInfo?.result?.user_type) {
      setUserType(resturentInfo?.result?.user_type);
    }
  }, [resturentInfo]);

  const { data: productsData, isLoading: productsLoading } = useQuery(
    [
      "products-list",
      fetchProductList,
      {
        ...options,
        enabled: user_type !== null && user_type !== "restaurant",
      },
    ],
    () => fetchProductList(username, user_type)
  );

  const { data: categories, isLoading: categoryLoading } = useQuery(
    [
      "categories-list",
      fetchCategories,
      {
        options,
      },
    ],
    () => fetchCategories({ username })
  );

  const { data: subCategories, isLoading: subCategoryLoading } = useQuery(
    [
      "sub-categories-list",
      selectedChip,
      {
        options,
        enabled: selectedCategory !== null ?true:false,
      },
    ],
    () => fetchSubCategories({ username,categoryID:selectedCategory })
  );

  const { data: recommendedData } = useQuery(
    [
      "recommended-list",
      selectedChip,
      {
        options,
        enabled: user_type !== null && user_type === "restaurant",
      },
    ],
    () => fetchRecommended({ username, user_type })
  );

  const { data: buttonInfo } = useQuery(
    ["button-info"],
    () => fetchMenuButtons({ username }),
    {
      options,
    }
  );

  const { data: themeSetting } = useQuery(
    ["theme-setting"],
    () => fetchThemeSetting({ username }),
    {
      options,
    }
  );

  React.useEffect(() => {
    function checkUserType() {
      const item = localStorage.getItem("pwa_user_type");
      if (item) {
        setUserType(item);
      }
    }
    window.addEventListener("storage", checkUserType);
    return () => {
      window.removeEventListener("storage", checkUserType);
    };
  }, []);

  React.useEffect(() => {
    setItemsLoading(true);
    let stuff = {};
    if (data?.data?.results?.length > 0 && user_type === "restaurant") {
      const formatedData = data.data.results.reduce((acc, item) => {
        //item includes boh category and subcategory remove from category and add to subcategory
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        if(!acc[item.sub_category]){
          acc[item.sub_category] = [];
        }
        acc[item.category_name].push(item);
        acc[item.sub_category].push(item);
        if(item.sub_category && item.category_name){
          acc[item.category_name] = acc[item.category_name].filter((v) => v.id !== item.id);
        }
        return acc;
      }, {});

      stuff = { ...formatedData };

    } else if (
      productsData?.data?.results?.length > 0 &&
      user_type === "auto_elec"
    ) {
      const formatedData = productsData.data.results.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});
      stuff = { ...formatedData };
    }
    if (recommendedData?.data?.results?.length > 0) {
      let recommendedObject = {};
      recommendedObject["Recommended"] = recommendedData.data.results;
      stuff = { ...recommendedObject, ...stuff };
    }

    if (selectedType === "all" || user_type === "auto_elec") {
      setMenuList(stuff);
    }

    if (selectedType !== "all" && user_type === "restaurant") {
      let filtered = {};
      Object.keys(stuff).forEach((item) => {
        filtered[item] = stuff[item].filter(
          (food) => food.food_type === selectedType
        );
      });
      stuff = filtered;
      setMenuList(stuff);
    }
    setItemsLoading(false);
  }, [data, productsData, recommendedData, selectedType, user_type,selectedCategory]);

  const clearSearch = () => {
    let stuff = {};
    if (data?.data?.results?.length > 0 && user_type === "restaurant") {
      const formatedData = data.data.results?.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        if(!acc[item.sub_category]){
          acc[item.sub_category] = [];
        }
        acc[item.category_name].push(item);
        acc[item.sub_category].push(item);
        return acc;
      }, {});
      stuff = { ...formatedData };
    } else if (
      productsData?.data?.results?.length > 0 &&
      user_type === "auto_elec"
    ) {
      const formatedData = productsData?.data.results.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});
      stuff = { ...formatedData };
    }
    if (recommendedData?.data?.results?.length > 0) {
      let recommendedObject = {};
      recommendedObject["Recommended"] = recommendedData.data.results;
      stuff = { ...recommendedObject, ...stuff };
    }
    setSelectedType("all");
    setMenuList(stuff);
  };

  const handleChipSelect = (chip) => {
    setSelectedChip(chip);
  };

  const handleSubChipSelect = (chip) => {
    setSelectedChip(chip);
    setSelectedSubcategory(chip);
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };


  React.useEffect(() => {
    if (selectedChip === "Recommended") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (ref.current) {
      var element = ref.current;
      var headerOffset = 270;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [selectedChip]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const FloatingFab = styled(Fab)(({ theme }) => ({
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: themeSetting?.data?.button_color,
    fontSize:'14px',
    color: themeSetting?.data?.button_text_color,
    border:'1px solid #fff',
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgb(37,42,47)",
      color: "#fff",
    },
  }));

  React.useEffect(() => {
    var manifest = {
      short_name: "Catzify",
      name: "Catzify",
      icons: [
        {
          src: "https://bdine-bucket.s3.ap-south-1.amazonaws.com/catzify/Other/favicon.ico",
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/png",
        },
        {
          src: "https://bdine-bucket.s3.ap-south-1.amazonaws.com/catzify/Other/banner4.png",
          type: "image/png",
          sizes: "192x192",
          purpose: "any",
        },
        {
          src: "https://bdine-bucket.s3.ap-south-1.amazonaws.com/catzify/Other/banner3.png",
          type: "image/png",
          sizes: "512x512",
          purpose: "any",
        },
      ],
      start_url: ".",
      display: "standalone",
      theme_color: 'black',
    };

    let path = window.location?.pathname?.replace("/", "");
    let basepath = path?.split("/")[0];

    manifest.scope = `https://catzify.com/${basepath}/`;
    manifest.start_url = `https://catzify.com/${basepath}/`;

    reactManifest.update(manifest, "#manifest-placeholder");
  }, []);

  React.useEffect(() => {
    if (Search.length > 0) {
      let filtered = {};
      Object.keys(menuList).forEach((item) => {
        filtered[item] = menuList[item].filter((food) =>
          food.name.toLowerCase().includes(Search.toLowerCase())
        );
      });
      setMenuList(filtered);
    } else {
      clearSearch();
    }
  }, [Search]);


  let [sum, setSum] = React.useState(0);

  React.useEffect(() => {
    let sum = 0;
    Object.keys(menuList).forEach((item) => {
      sum += menuList[item].length;
    });
    setSum(sum);
  }, [menuList]);

  return (
    <React.Fragment>
      {!resturentInfo?.result?.only_about_us ? (
        <>
          <Grid
            sx={{
              marginInline: 0,
              position: "sticky",
              top: -1,
              zIndex: 15,

            }}
          >
            <Header themeData={themeSetting?.data} />
          </Grid>
          <Grid
            sx={{
              marginInline: 0,
              position: "sticky",
              top: 125,
              zIndex: 15,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              background: themeSetting?.data?.theme_color

            }}
          >
            <CustomSearchInput
              label={`Search ${
                user_type === "restaurant" ? "Menu" : "Product"
              }`}
              value={Search}
              onChange={handleSearch}
              themeData={themeSetting?.data} 
            />
          </Grid>
          {resturentInfo?.result?.banner_images?.length > 0 && (
            <HeroCarousel resturentInfo={resturentInfo} themeData={themeSetting?.data} />
          )}
          <Grid
            sx={{
              marginInline: 0,
              position: "sticky",
              top: 187,
              zIndex: 15,
              }}
          >
            {user_type === "restaurant" &&
              !categoryLoading &&
              Object.keys(menuList).length !== 0 && (
                <TypeChipsList
                  categories={[
                    { name: "All", value: "all" },
                    { name: "Veg", value: "veg" },
                    { name: "Non-Veg", value: "non-veg" },
                  ]}
                  selectedType={selectedType}
                  handleTypeSelect={handleTypeSelect}
                  themeData={themeSetting?.data} 
                />
              )}
              
          </Grid>
          <Grid
            sx={{
              marginInline: 0,
              position: "sticky",
              top: user_type === "restaurant" ? 233 : 184,
              zIndex: 15,
              
            }}
          >
            {(!categoryLoading && Object.keys(menuList).length !== 0) && (
              <ChipsList
                categories={categories}
                selected={selectedChip}
                handleChipSelect={handleChipSelect}
                menuList={menuList}
                themeData={themeSetting?.data} 
                setSelectedCategory={setSelectedCategory}
              />
            )}
            {(!subCategoryLoading && Object.keys(menuList)?.length !== 0) && (
              <SubChipsList
                subCategories={subCategories||[]}
                selected={selectedSubcategory}
                handleSubChipSelect={handleSubChipSelect}
                menuList={menuList}
                themeData={themeSetting?.data} 
              />
            )}
          </Grid>
          {user_type === "restaurant" &&
            Object.keys(menuList).length === 0 &&
            resturentInfo &&
            (isLoading || itemsLoading) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  src="https://lottie.host/8d8f9523-4f00-4b12-81ee-d1180cc6ce44/UIXi6ZK5LO.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "250px", height: "250px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            )}

          {user_type !== "restaurant" &&
            Object.keys(menuList).length === 0 &&
            resturentInfo &&
            (productsLoading || itemsLoading) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  src="https://lottie.host/8d8f9523-4f00-4b12-81ee-d1180cc6ce44/UIXi6ZK5LO.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "250px", height: "250px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            )}

          {resturentInfo && categoryLoading && (
            <Skeleton
              variant="rectangular"
              sx={{ my: 4, mx: 3 }}
              width={"90%"}
              height={60}
            />
          )}

          {resturentInfo &&
            user_type === "restaurant" &&
            !isLoading &&
            !itemsLoading &&
            Object.keys(menuList).length > 0 &&
            Object.keys(menuList).map((item) => (
              <>
                {menuList[item].length > 0 && (
                  <FoodAccordion
                    ref={(selectedChip === item||menuList[item].name===selectedSubcategory) ? ref : null}
                    key={menuList[item].id}
                    label={item}
                    count={menuList[item].length}
                    data={menuList[item]}
                    themeData={themeSetting?.data} 

                  />
                )}
              </>
            ))}

          {resturentInfo &&
            user_type === "auto_elec" &&
            !productsLoading &&
            !itemsLoading &&
            Object.keys(menuList).map((item) => (
              <>
                {menuList[item].length > 0 && (
                  <FoodAccordion
                    ref={selectedChip === item ? ref : null}
                    key={menuList[item].id}
                    label={item}
                    count={menuList[item].length}
                    data={menuList[item]}
                    themeData={themeSetting?.data} 
                  />
                )}
              </>
            ))}

          {user_type === "restaurant" &&
            resturentInfo &&
            (isLoading || itemsLoading) && (
              <Skeleton
                variant="rectangular"
                sx={{ my: 4, mx: 3 }}
                width={"90%"}
                height={100}
              />
            )}

          {user_type === "auto_elec" &&
            resturentInfo &&
            (productsLoading || itemsLoading) && (
              <Skeleton
                variant="rectangular"
                sx={{ my: 4, mx: 3 }}
                width={"90%"}
                height={100}
              />
            )}

          {user_type === "restaurant" &&
            resturentInfo &&
            !isLoading &&
            !itemsLoading &&
            Object.keys(menuList).length === 0 && (
              <lottie-player
                src="https://assets4.lottiefiles.com/packages/lf20_xy4tg1op.json"
                background="transparent"
                speed="0.5"
                style={{ width: "100%", height: "250px", marginTop: "150px" }}
                loop
                autoplay
              ></lottie-player>
            )}

          {user_type === "auto_elec" &&
            resturentInfo &&
            !productsLoading &&
            !itemsLoading &&
            Object.keys(menuList).length === 0 && (
              <lottie-player
                src="https://assets4.lottiefiles.com/packages/lf20_xy4tg1op.json"
                background="transparent"
                speed="0.5"
                style={{ width: "100%", height: "250px", marginTop: "150px" }}
                loop
                autoplay
              ></lottie-player>
            )}

          {resturentInfo && Object.keys(menuList).length > 0 && sum === 0 && (
            <Box
              sx={{
                xs: { display: "flex", flexDirection: "column" },
                md: { display: "none" },
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              <img src={noresults} height="20%" width="30%" alt="empty" />
            </Box>
          )}
          <FloatingFab variant="extended" onClick={() => setToggleMenu(true)}>
            <img
              src={
                toggleMenu
                  ? close
                  : !toggleMenu &&
                    buttonInfo?.data?.results &&
                    buttonInfo?.data?.results?.length > 0 &&
                    buttonInfo?.data?.results[0]?.image &&
                    buttonInfo?.data?.results[0]?.image?.length > 0
                  ? buttonInfo?.data?.results[0]?.image
                  : product
              }
              height="30px"
              width="30px"
              alt="fab"
              style={{ marginRight: "10px" }}
            />
            {toggleMenu
              ? "Close"
              : buttonInfo?.data?.results &&
                buttonInfo?.data?.results?.length > 0 &&
                buttonInfo?.data?.results[0]?.word &&
                buttonInfo?.data?.results[0]?.word?.length > 0
              ? buttonInfo?.data?.results[0]?.word
              : "Menu"}
          </FloatingFab>
          <MenuDialog
            title={buttonInfo?.data?.results[0]?.word}
            open={toggleMenu}
            setOpen={setToggleMenu}
            resturentInfo={resturentInfo}
            options={categories}
            selected={selectedChip}
            handleChipSelect={handleChipSelect}
            themeData={themeSetting?.data} 

          />
        </>
      ) : (
        resturentInfo?.result?.only_about_us && <MainAboutUs />
      )}
      {resturentInfo?.result?.only_about_us === undefined && (
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_xy4tg1op.json"
          background="transparent"
          speed="0.5"
          style={{ width: "100%", height: "250px", marginTop: "150px" }}
          loop
          autoplay
        ></lottie-player>
      )}
    </React.Fragment>
  );
}

export default App;
