import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";


const CustomSearchInput = ({ label, onChange, value,themeData, ...rest }) => {

  const CustomSearchStyle = styled(TextField)({
    background: themeData?.theme_color,
    paddingBlock: "12px",
    width: "95% !important",
    //on mobile
    "@media (max-width: 600px)": {
      marginInline: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:  themeData?.font_color,
      },
      "&:hover fieldset": {
        borderColor:  themeData?.font_color,
      },
      "&.Mui-focused fieldset": {
        borderColor:  themeData?.font_color,
      },
      "& .MuiInputBase-input": {
        color: themeData?.font_color,
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "500",
        fontFamily: "Roboto",
        fontStyle: "normal",
        padding: "8px",
      },
      "& .MuiInputBase-input::placeholder": {
        color: themeData?.font_color,
      }
    },
  });
  return (
    <CustomSearchStyle
      id="outlined-basic"
      placeholder={label}
      onChange={onChange}
      value={value}
      {...rest}
      variant="outlined"
      sx={{ width: "100%", backgroundColor:themeData?.theme_color,mx:2}}
      InputProps={{
        startAdornment: <SearchIcon sx={{ color:  themeData?.font_color }} />,
      }}
    />
  );
};

export default CustomSearchInput;
