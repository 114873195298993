import React from "react";
import { Grid } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import nonveg from "../assets/utils/nonveg.svg";
import veg from "../assets/utils/veg.svg";


const TypeChipsList = ({ categories, selectedType, handleTypeSelect,themeData }) => {

  const CustomChip = styled(Chip)({
    [`&.${chipClasses.filled}`]: {
      backgroundColor: 'black',
    },
  });
  return (
    <Grid
      container
      paddingX={3}
      paddingY={1}  
      gap={1}
      display="flex"
      flexWrap="nowrap"
      maxWidth="100%"
      backgroundColor='white'
      sx={{
        pt: 2,
        overflowX: "auto",
        paddingBottom: 1,
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {categories?.map((chip, i) => (
        <Grid item key={i}>
          <CustomChip
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize:'12px',
                }}
              >
                {chip.name !== "All" && (
                  <img
                    src={chip.name === "Veg" ? veg : nonveg}
                    alt={"Type"}
                    style={{ marginRight: "5px",fontSize:'12px' }}
                    loading="lazy"
                  />
                )}
                {chip.name}
              </div>
            }
            variant={selectedType === chip.value ? "filled" : "outlined"}
            sx={{
              border: "1px solid #CACACA",
              color: selectedType === chip.value ? "#fff" : "black",
            }}
            onClick={() => handleTypeSelect(chip.value)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TypeChipsList;
